import logo from './primary-white.svg';
import './App.css';

function App() {
  const datetime = new Date();
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-content">
          <img src={logo} className="App-logo" alt="Onosendai, Limitada" />
        </div>
        {/*
        <div className="App-bottom">
          {datetime.toString()}
        </div>
  */}
      </header>
    </div>
  );
}

export default App;
